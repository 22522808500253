
import React from 'react'
import '../css/email.css'

class Email extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
      }  
    

    render() {
      return <div STYLE="margin: 0; padding: 20px; background-color: #ffffff; font-family: 'Lato',Arial,Helvetica,sans-serif;">
        <div> 
          <div STYLE="margin-bottom: 10px;">
            Dear Hiring Manager, 
          </div>
          <div STYLE="margin-bottom: 10px;">
            Please find attached Samuel Guthrie's application for the 
            <a href="https://www.archjobs.co.uk/job/senior-architectural-technician-with-hssp-architects-in-melton-mowbray-leicestershire-50" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.archjobs.co.uk/job/senior-architectural-technician-with-hssp-architects-in-melton-mowbray-leicestershire-50&amp;source=gmail&amp;ust=1662041324297000&amp;usg=AOvVaw3DdeixAF3rUn_aUErZ00Nc">
              Senior Architectural Technician role with HSSP Architects in Melton Mowbray, Leicestershire
            </a> 
          </div>
          <div STYLE="margin-bottom: 20px;">
            If you would like to get in touch with the candidate, please contact them at 
            <a href="mailto:samueldguthrie@gmail.com" target="_blank">samueldguthrie@gmail.com</a> 
            directly. </div>
          <div STYLE="margin-bottom: 20px;">        
            <div STYLE="margin-bottom: 4px; font-weight: 600;">Who We Are</div>        
            <div STYLE="margin-bottom: 4px;">We are <a href="https://www.archjobs.co.uk/" target="_blank">ArchJobs</a>, a brand new curated Job Board that focuses solely on Architecture roles across the UK - all our jobs are hand picked and uploaded by ourselves. </div>
            <div>Our goal is to work with the most prestigious architectural firms and help you receive better quality applications. While we are establishing our brand, all job postings are 100% free, so please get in touch if you have any questions or would like to post a job.</div>
            </div>
          <div STYLE="margin-bottom: 10px;">Kind regards,  
            <div STYLE="margin-top: 20px; font-weight: 600; font-size: 120%;"> Aylin Round</div>      
          </div>
          <div STYLE="margin-bottom: 20px;">
            <img STYLE="height: 36px; width: 36px; margin-bottom: -2px; margin-left: 2px; margin-right: 4px;" src="https://www.archjobs.co.uk/logo.png" alt="ArchJobs Logo"></img>Founder - <a href="https://www.archjobs.co.uk/" target="_blank" STYLE="color: #000; text-decoration: underline;">ArchJobs</a> a curated Job Board for Architecture roles across the UK    
          </div>    
          <div STYLE="font-size: 90%; margin-bottom: 20px;">
            <div STYLE="margin-bottom: 10px;">        
              <div STYLE="margin-bottom: 2px; font-weight: 600;">This role is no longer available?</div>       
              We are more than happy to close the job posting on ArchJobs so you don’t receive further applications - just reply to this email and let us know.    
            </div>    
            <div STYLE="margin-bottom: 10px;">        
              <div STYLE="margin-bottom: 2px; font-weight: 600;">Got a job to post?</div>
              You can also reply with the details and we’ll be in touch.    
            </div>
          </div>
          <div STYLE="margin-bottom: 10px; font-size: 90%;">
            Follow our Social Media
            <div STYLE="margin-bottom: 2px; margin-top: 2px;"><a href="https://www.linkedin.com/in/aylin-round/" target="_blank"><img STYLE="height: 16px; margin-bottom: -2px; margin-left: 2px; margin-right: 2px;" src="https://cdn.archjobs.co.uk/file/ArchJobs/logos/LILogo.png" alt="Linkedin Logo"></img>https://www.linkedin.com/in/aylin-round/</a></div>
            <div STYLE="margin-bottom: 2px; margin-top: 2px;"><a href="https://www.linkedin.com/company/archjobsuk/" target="_blank"><img STYLE="height: 16px; margin-bottom: -2px; margin-left: 2px; margin-right: 2px;" src="https://cdn.archjobs.co.uk/file/ArchJobs/logos/LILogo.png" alt="Linkedin Logo"></img>https://www.linkedin.com/company/archjobsuk/</a></div>
            {/* <div STYLE="margin-bottom: 2px; margin-top: 2px;"><a href="https://twitter.com/ArchJobsUK" target="_blank"><img STYLE="height: 16px; margin-bottom: -2px; margin-left: 2px; margin-right: 2px;" src="https://cdn.archjobs.co.uk/file/ArchJobs/logos/TwitterLogo.png" alt="Twitter Logo"></img>https://twitter.com/ArchJobsUK</a></div> */}
          </div>
      </div>
      </div>
    }
  }

  export default Email