
import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Button from './Buttons.js'
import Autocomplete from '@mui/material/Autocomplete';
import tags from '../data/tags.js'
import Quill from 'quill';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import {MdClose} from 'react-icons/md';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { BsLaptop, BsChevronLeft } from 'react-icons/bs'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const checkoutLinks = {
  "basic": "https://buy.stripe.com/8wM5mf8gO0i6eJy148",
  "standard": "https://buy.stripe.com/fZe9Cv2Wu4ymgRG4gl",
  "premium": "https://buy.stripe.com/3csg0T54Ce8W9pe5kq"
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};



class PostJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            job_submission_status: {},
            success_open: false,
            package: "basic",
        }
        this.quill = null
        this.multiSelectValues = {}
      }    
    
      componentDidMount = () => {
        if (!this.quill){
          this.quill = new Quill('#jobdescriptioninput', {
            theme: 'snow',
            formats: [
              'bold',
              'header',
              'italic',
              'link',
              'list',
              'blockquote',
              'image',
              'indent'
            ],
            modules: {
              toolbar: [
                [{'header': 1}, {'header': 2}],
                ['bold', 'italic', 'link'],
                ['blockquote', {'list': 'ordered'}, {'list': 'bullet'}],
              ],
              clipboard: {
                matchVisual: false // https://quilljs.com/docs/modules/clipboard/#matchvisual
              }
            }
          });
        }
        if (window.location.search){
          var urlParams = new URLSearchParams(window.location.search);
          var SelectedPackage = urlParams.get('package')
          if (SelectedPackage){
            this.setState({package: SelectedPackage})
          }
        }
        window.scrollTo(0, 0)
      }

      updateMultiSelectValue = (id, value) => {
        this.multiSelectValues[id] = value
      }

      Submit = () => {
        console.log("submitting")
        this.setState({job_submission_status: {status: "loading"}})

        const fields = [
            {id: "company_name", field_name:"company_name", required: true, label: "Company Name"},
            {id: "company_site", field_name:"company_site", required: true, label: "Company Site"},
            {id: "name", field_name:"contact_name", required: true, label: "Contact Name"},
            {id: "phone", field_name:"phone", required: true, label: "Contact Number"},
            {id: "email", field_name:"email", required: true, label: "Email Address"},
            {id: "job_title", field_name:"job_title", required: false},
            {id: "job_location", field_name:"job_location", required: false},
            {id: "job_type", field_name:"job_type", required: false},
          ]

          var jobSubmission = {data: {}}
      
          for (const field of fields){
            var input = document.getElementById(field.id)
      
            if (input != null){
              if (field.type == "checkbox"){
                jobSubmission.data[field.field_name] = input.checked ? 1: 0
              } else if(field.type == "numeric") {
                var int_value = parseInt(input.value)
                if (int_value < 0){
                  int_value = null
                }
                jobSubmission.data[field.field_name] = int_value
              } else {
                if ((input.value == "") && (field.required == true)){
                  this.setState({job_submission_status: {status: "error", message: "Required field "+field.label+" not filled out", field: field.id, field_message: "Required Field"}})
                  return
                } else {
                  jobSubmission.data[field.field_name] = input.value
                }
                jobSubmission.data[field.field_name] = input.value
              }
            } else {
              console.log("Couldnt get input: ", field.id)
            }
        }
    
        //job description
        if (this.quill){
            var job_description_content = this.quill.root.innerHTML
            // console.log(job_description_content)
            jobSubmission.data["job_description"] = job_description_content
        }
        jobSubmission.data["package"] = this.state.package

        console.log(jobSubmission)
        const SubmitUrl = "https://api.archjobs.co.uk/customer_signup" 

        axios.post(SubmitUrl, {
            body: JSON.stringify(jobSubmission),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            }
          }).then(response => {
            console.log(response.data)
            if ("error" in response.data){
                this.setState({job_submission_status: {status: "error", message: "Could not submit form, please email us at hello@archjobs.co.uk and let us know!"}})
            } else {
                this.setState({success_open: true, job_submission_status: {status: "success"}})
                window.open(checkoutLinks[jobSubmission.data["package"]], '_blank');
            }
          }).catch(error =>{
            this.setState({job_submission_status: {status: "error", message: "Could not submit form, please email us at hello@archjobs.co.uk instead"}})
          })
      }

      handleClose = () => {
        this.setState({success_open: false})
      }

      handlePackageChange = (newPackage) => {
        this.setState({package: newPackage})
      }

    render() {

    var error_text = ""

      return (
        <div className="PostJobPage">
          <Link className="ResultsGoBack" to={-1} ><BsChevronLeft className="BackArrow"/>Go Back</Link>
            <div className="PostJobFormContainer">
                <div className="PostJobForm">
                    <div className="PostJobFormHeader"> 
                    Submit Your Job Advert and We'll Be in Touch
                    </div>
                    <div className="PostJobFormText"> 
                    
                        {/* We will review whether your company/practice is a good fit for our job board, and someone from the team will get in touch ASAP.   */}
                    </div>
                    {/* <div className="PostJobFormNotice"> 
                        Already a client? Please email <a href="mailto:hello@archjobs.co.uk">hello@archjobs.co.uk</a>.
                    </div> */}
                    <div className="PostJobFormText"> 
                    <strong>Important:</strong> We only accept job adverts from architectural and multi-disciplinary companies within the built environment in the UK. If you're unsure, please email us at hello@archjobs.co.uk.
                         {/* that meet the following criteria:
                        <ul>
                            <li>You are looking for architectural staff</li>
                            <li>Your company is based in the UK</li>
                            <li>You are not a recruitment agency</li>
                        </ul> */}
                    </div>
                    <div className="PostJobFormDivider"></div>
                    <div className="PostJobFormHeader">
                        Basic Company and Contact Details
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Company Name</div>
                        <TextField
                            variant="outlined"
                            id="company_name"
                            className="PostJobInput"
                            type="text"
                            placeholder="Company Name"
                            error={this.state.job_submission_status.field == "company_name"}
                            helperText={(this.state.job_submission_status.field == "company_name") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Company Website</div>
                        <TextField
                            variant="outlined"
                            id="company_site"
                            className="PostJobInput"
                            type="text"
                            placeholder="Company Website"
                            error={this.state.job_submission_status.field == "company_site"}
                            helperText={(this.state.job_submission_status.field == "company_site") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Contact Person</div>
                        <TextField
                            variant="outlined"
                            id="name"
                            className="PostJobInput"
                            type="text"
                            placeholder="Full Name"
                            error={this.state.job_submission_status.field == "name"}
                            helperText={(this.state.job_submission_status.field == "name") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Contact Number</div>
                        <TextField
                            variant="outlined"
                            id="phone"
                            className="PostJobInput"
                            type="text"
                            placeholder="Contact Number"
                            error={this.state.job_submission_status.field == "phone"}
                            helperText={(this.state.job_submission_status.field == "phone") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Email Address</div>
                        <TextField
                            variant="outlined"
                            id="email"
                            className="PostJobInput"
                            type="email"
                            placeholder="Contact Email"
                            error={this.state.job_submission_status.field == "email"}
                            helperText={(this.state.job_submission_status.field == "email") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormDivider"></div>
                    <div className="PostJobFormHeader">
                        Job Information
                    </div>
                    <div className="PostJobFormText"> 
                    <strong>Please note:</strong> Your job advert won’t be automatically added to our job board. Our team will review it manually and contact you ASAP before it’s published. 🙂
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Job Title</div>
                        <Autocomplete
                            required
                            id="job_title"
                            freeSolo
                            className="PostJobInput"
                            options={tags.JobTitles}
                            renderInput={(params) => <TextField {...params} placeholder="Job Title" />}
                        />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Job Location(s)</div>
                        <TextField
                            variant="outlined"
                            id="job_location"
                            className="PostJobInput"
                            type="text"
                            placeholder="Please provide city or postcode"
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Job Type</div>
                        <Autocomplete
                            required
                            id="job_type"
                            freeSolo
                            className="PostJobInput"
                            options={tags.JobType}
                            renderInput={(params) => <TextField {...params} placeholder="Job Type" />}
                        />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Job Description <div className="JobSubmissionsubtitle"> Don't have one yet? We can send you some templates to get you started</div> </div>
                        <div className="JobDescriptionInputContainer">
                            <div id="jobdescriptioninput" className="JobDescriptionInput" ></div>
                        </div>
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Package</div>
                        <div className="PostJobPackageContainer">
                            <div className={"PostJobPackage"+(this.state.package == "basic"?" PostJobPackageSelected":"")} onClick={() => {this.handlePackageChange("basic")}} id="basic" >
                              <div className="PackageTitle">Basic</div>
                              {/* <div className="PackageDescription">A basic Job Advert posted on ArchJobs</div> */}
                              <div className="PackagePrice">£129</div>
                            </div>
                            <div className={"PostJobPackage"+(this.state.package == "standard"?" PostJobPackageSelected":"")}  onClick={() => {this.handlePackageChange("standard")}} id="standard" >
                              <div className="PackageTitle">Standard</div>
                              {/* <div className="PackageDescription">A standard Job Advert, posted on ArchJobs & across our social media channels</div> */}
                              <div className="PackagePrice">£149</div>
                            </div>
                            <div className={"PostJobPackage"+(this.state.package == "premium"?" PostJobPackageSelected":"")}  onClick={() => {this.handlePackageChange("premium")}} id="premium" >
                              <div className="PackageTitle">Premium</div>
                              {/* <div className="PackageDescription">A premium Job Advert, sponsored post on the website & posted across our social media channels</div> */}
                              <div className="PackagePrice">£189</div>
                            </div>
                        </div>
                    </div>
                    <div className="PostJobFormSubmit">
                        <a href="/post-job" target="_blank" className="WhyWorkWithUsLink" style={{textDecoration: "underline"}}>What is included?</a>
                    </div>
                    <div className="PostJobFormSubmit"> 
                        <Button btnClassName="JobAdHeaderApplyButton" onClickFunc = {() => {this.Submit()}}>Check Out</Button>
                    </div>
                    <div className="PostJobFormError"> 
                        {(this.state.job_submission_status.status == "error") && this.state.job_submission_status.message}
                    </div>
                </div>
            </div>
            <BootstrapDialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.success_open}
                className="SuccessModal"
            >
                <div className="ModalClose"><MdClose className="CloseIcon" onClick={this.handleClose}></MdClose></div>
                <div className="SuccessModalTitle">One more step...</div>
                <div className="SuccessModalText">Please proceed to the payment page that opened in a new tab to complete your purchase. <p/>If you encounter any issues, please email aylin@archjobs.co.uk</div>
                                          
            </BootstrapDialog>
        </div>
      )
    }
  }

  export default PostJob