
import React from 'react'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { MdMailOutline } from 'react-icons/md'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import axios from 'axios';
import Button from './Buttons';

const SubmitUrl = "https://api.archjobs.co.uk/email_signup"

const NotifcationIcon = <svg stroke="currentColor" className="AlertIcon" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><desc></desc><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path><path d="M9 17v1a3 3 0 0 0 6 0v-1"></path><path d="M21 6.727a11.05 11.05 0 0 0 -2.794 -3.727"></path><path d="M3 6.727a11.05 11.05 0 0 1 2.792 -3.727"></path></svg>

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

class WeeklyEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          status: {}
        }
        this.version = 0
      }  

    clearStatus = (version) => {
      if (version == this.version){
        this.setState({status: {}})
      }
    }
      
    signUp= () => {
      this.version+=1
      const version = this.version
      this.setState({status: {}})
      var email_submit = {}
      setTimeout(() => {
        this.clearStatus(version)
      }, 5000);
      var input = document.getElementById("email")
      var email = input.value
      console.log(validateEmail(email))
      if ((email == "") || (!validateEmail(email))){
        this.setState({status: {type: "error", message: "Please submit a valid email address"}})
        return
      }
      email_submit["email"] = email
      var submit_date = new Date()
      email_submit["signed_up"] = submit_date.toISOString().replace("T", " ").split(".")[0]
      email_submit["subscribed"] = 1
      axios.post(SubmitUrl, {
        body: JSON.stringify(email_submit),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        }
      }).then(response => {
        console.log(response.data)
        if (response.data.status == "success"){
          this.setState({status: {type: "success", message: response.data.message}})
          input.value = ""
        } else {
          this.setState({status: {type: "error", message: "Error submitting email address: "+JSON.stringify(response.data)}})
        }
      }).catch(error =>{
        console.log("error: ", error)
        var ErrorMessage
        if (error.response) {
          ErrorMessage = error.response.data
        } else {
          ErrorMessage = error.message
        }
        this.setState({status: {type: "error", message: "Network error submitting email address: "+JSON.stringify(ErrorMessage)}})
      })
    }

    render() {

      var messageStyle = {}
      if (this.state.status.type == "error"){
        messageStyle = {color: "#E96565"}
      }
      if (this.state.status.type == "success"){
        messageStyle = {color: "#1B771F"}
      }

      if (this.props.type == "banner"){
        return <div className="WeeklyAlertBanner">
        <div className="WeeklyAlertHeader">Subscribe to our weekly Friday Newsletter</div>
        {/* <div className="PostJobAdText">Receive weekly job highlights and industry insights</div> */}
        <div className="WeeklyUpdateSignUp">
          <Link to="/newsletter"><Button btnClassName="SearchButton">Find out more</Button></Link>
        </div>
      </div>
      }

      return  <div className="WeeklyAlert">
        <div className="WeeklyAlertHeader">Newsletter {NotifcationIcon}</div>
        <div className="PostJobAdText">Get expert career advice, job search tips, market insights and top UK architecture roles - delivered every Friday.</div>
        <div className="WeeklyUpdateSignUp">
        <Link className="PostJobAdLink link" to="/newsletter">
        Sign up for Free
              </Link>
        </div>
        <div className="WeeklyUpdateMessage" style={messageStyle}>
            {this.state.status.message?  this.state.status.message: ""}
        </div>
      </div>
    }
  }

  export default WeeklyEmail