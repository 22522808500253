import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { MdMailOutline } from 'react-icons/md'
import axios from 'axios';
import Button from './Buttons';
import {Helmet} from "react-helmet";

const SubmitUrl = "https://api.archjobs.co.uk/email_signup"

const NotifcationIcon = <svg stroke="currentColor" className="AlertIcon" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><desc></desc><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path><path d="M9 17v1a3 3 0 0 0 6 0v-1"></path><path d="M21 6.727a11.05 11.05 0 0 0 -2.794 -3.727"></path><path d="M3 6.727a11.05 11.05 0 0 1 2.792 -3.727"></path></svg>
const Check = <svg className="Check" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path></svg>


const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

class WeeklyEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          status: {}
        }
        this.version = 0
      }  

    clearStatus = (version) => {
      if (version == this.version){
        this.setState({status: {}})
      }
    }
      
    signUp= () => {
      this.version+=1
      const version = this.version
      this.setState({status: {}})
      var email_submit = {}
      setTimeout(() => {
        this.clearStatus(version)
      }, 5000);
      let emailId = "email"
      if (this.props.emailId){
        emailId = this.props.emailId
      }
      var input = document.getElementById(emailId)
      var email = input.value
      console.log(validateEmail(email))
      if ((email == "") || (!validateEmail(email))){
        this.setState({status: {type: "error", message: "Please submit a valid email address"}})
        return
      }
      email_submit["email"] = email
      var submit_date = new Date()
      email_submit["signed_up"] = submit_date.toISOString().replace("T", " ").split(".")[0]
      email_submit["subscribed"] = 1
      axios.post(SubmitUrl, {
        body: JSON.stringify(email_submit),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        }
      }).then(response => {
        console.log(response.data)
        if (response.data.status == "success"){
          this.setState({status: {type: "success", message: response.data.message}})
          input.value = ""

          if (this.props.successFunction){
            this.props.successFunction()
          }
        } else {
          this.setState({status: {type: "error", message: "Error submitting email address: "+JSON.stringify(response.data)}})
        }
      }).catch(error =>{
        console.log("error: ", error)
        var ErrorMessage
        if (error.response) {
          ErrorMessage = error.response.data
        } else {
          ErrorMessage = error.message
        }
        this.setState({status: {type: "error", message: "Network error submitting email address: "+JSON.stringify(ErrorMessage)}})
      })
    }

    render() {

      var messageStyle = {}
      if (this.state.status.type == "error"){
        messageStyle = {color: "#E96565"}
      }
      if (this.state.status.type == "success"){
        messageStyle = {color: "#1B771F"}
      }
      let emailId = "email"
      if (this.props.emailId){
        emailId = this.props.emailId
      }

      return  <div className="NewsletterSignupContainer">
        <div className="NewsletterSignup">
          <TextField
              variant="outlined"
              id={emailId}
              className="JobAlertInputWide"
              type="email"
              placeholder="Enter your email"
              InputProps={{
                startAdornment: <InputAdornment position="start"><MdMailOutline className='mailImage'/></InputAdornment>

              }}
              />
          <div className="NewsletterSignupButton">
            <Button btnClassName="SearchButton" onClickFunc={this.signUp}>Sign Up</Button>
          </div>
        </div>
        <div className="WeeklyUpdateMessage" style={messageStyle}>
            {this.state.status.message?  this.state.status.message: ""}
        </div>
      </div>
    }
  }



class NewsLetter extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }
  


  render() {

    return <div className="NewsletterPage" >
      <Helmet>
    <meta charSet="utf-8" />
      <title>ArchJobs | Newsletter </title>
        <link rel="canonical" href={"https://www.archjobs.co.uk/newsletter"} />
        <meta name="description" content={"Sign up to our Newsletter and get expert career advice, job tips, market insights and top UK architecture roles—straight to your inbox." }/>
        <meta name="og:title" content={"ArchJobs | Newsletter"}/>
        <meta name="og:type" content={"website"}/>
        <meta name="og:image" content={"https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/archjobs_banner.png"}/>
        <meta name="og:url" content={"https://www.archjobs.co.uk/newsletter"}/>
    </Helmet>
      <div className="NewsletterPageBg"></div>
      <div className="HomePageContent">
        <h1 className="NewsletterPageTitle">Stay Ahead in Your Architecture Career</h1>
        <div className="WhyWorkWithUsHeaderText">Sign up for our free weekly newsletter and get expert career advice, job search tips, market insights and a roundup of the best UK architecture roles - straight to your inbox. 
              {/* <p>Discover practices offering excellent benefits packages, part-time and contract roles.</p> */}
              {/* <p>We keep you in the loop with a quick summary of the past 7 days and any market trends we notice.</p> */}
               {/* <p> We optimise your job adverts, reduce gender bias, and make sure they stand out from the crowd. We know what job seekers are looking for and we'll highlight the key aspects of your job opportunities that will appeal to them. */}
              </div>
        {/* <h2 className="NewsletterPageSubtitle">Sign up for our free weekly newsletter and be the first to know about hiring opportunities. Discover practices offering excellent benefits packages, part-time and contract roles. We'll also keep you in the loop with a quick summary of the past 7 days and any market trends we notice. </h2> */}
        <div className="NewsletterEmailContainer">
          <WeeklyEmail/>
        </div>
        <div className="WhyWorkWithUsHeaderText"><strong>Don't miss out –</strong> stay informed and take the next step in your career! ✌️</div>
        {/* <h3 className="NewsletterPageSubtitle">Don't miss out – sign up now and stay connected with the architectural job market.</h3> */}
      </div>

    </div>
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  const propsCopy = {...props}
  propsCopy.timeout = 500
  return <Slide direction="up" ref={ref} {...propsCopy} />;
});


export function  NewsLetterPopUp() {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    // set open after 3 seconds
    // check Localstorage for last shown
    var lastShown = localStorage.getItem("lastShown")
    let showPopUp = true
    if (lastShown){
      var lastShownDate = new Date(lastShown)
      var today = new Date()
      var diffTime = Math.abs(today - lastShownDate);
      var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays < 7){
        showPopUp = false
      }
    }
    if (showPopUp){
      setTimeout(() => {
        setOpen(true);
        localStorage.setItem("lastShown", new Date())
      }, 3000);
    }

  }, []);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleCloseDelay = () => {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    };
  
    return (

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className="NewsletterPopUp"
        >

        <div className="PopUpContent">
            <h1 className="PopUpTitle">Stay Ahead in Your Architecture Career</h1>
            <div className="PopUpText">
              <p>{Check} Career Advice, Market Insights and the Best UK Architecture Roles</p>
              {/* <p>Get the latest Market Trends and Insights</p> */}
              {/* <p>{Check} 1 Email a Week, no Spam</p> */}
            </div>
            {/* <h2 className="NewsletterPageSubtitle">Sign up for our free weekly newsletter and be the first to know about hiring opportunities. Discover practices offering excellent benefits packages, part-time and contract roles. We'll also keep you in the loop with a quick summary of the past 7 days and any market trends we notice. </h2> */}
            <div className="NewsletterEmailContainer">
              <WeeklyEmail successFunction={handleCloseDelay} emailId="PopUpEmail"/>
            </div>
            {/* <div className="WhyWorkWithUsHeaderText"><strong>Don't miss out –</strong> sign up now and stay updated with the architectural job market.</div> */}
            {/* <h3 className="NewsletterPageSubtitle">Don't miss out – sign up now and stay connected with the architectural job market.</h3> */}
          </div>

        </Dialog>

    );
}

/* export the component to be used in other components */
export {WeeklyEmail} 
export default NewsLetter