import withRouter from "../withRouter";
import React, { Component } from 'react';
import Helmet from 'react-helmet'
import './articles.css'
import ArticleData from './ArticleData';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { WeeklyEmail } from '../components/NewsLetter';

class ArticleList extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }


    render(){
        let authorData = {}
        let url = "https://www.archjobs.co.uk/articles"
        let title = "ArchJobs Articles | Architectural Insights & Career Tips"
        let description = "Stay updated with the latest architectural insights, job search tips, and industry news on ArchJobs Articles."
        let subtitle = "Get helpful tips on writing and structuring your architectural CV and portfolio, preparing for job interviews, understanding UK architectural salaries and more. Our articles are tailored for architectural job seekers living in or considering a move to the UK or Dubai, providing valuable insights to help you secure your next role."
        let authorComponent

        let ArticleListTitle = "Architectural Articles and Guides: Navigating Careers, UK Salaries and Job Search Strategies"
        let ArticleList = []
        
        for (const article in ArticleData.ArticleData){
            if (article !== "YourArticle"){ //this is the demo article
                ArticleData.ArticleData[article].dataName = article
                ArticleList.push(ArticleData.ArticleData[article])
            }
        }
        
        if (this.props.dubai){
            ArticleList = ArticleList.filter(article => article.dubai == true)
            url = "https://www.archjobsrecruitment.com/articles"
            title = "ArchJobs Dubai Articles | Architectural Insights & Career Tips"
            description = "Stay updated with the latest architectural insights, job search tips and industry news on ArchJobs Articles."
            ArticleListTitle = "Dubai Architectural Articles and Guides: Navigating Careers and Job Search Strategies"
            // Babycoder change below for dubai text
            subtitle = "Get helpful tips for writing and structuring your architectural CV and portfolio, preparing for job interviews, and more. Our articles are tailored for architectural job seekers living in or considering a move to the Dubai, providing the best information to help you secure your next position"
        }

        ArticleList.sort((a,b) => b.published - a.published)

        if (this.props.author in ArticleData.AuthorData){
            ArticleList = ArticleList.filter(article => article.author == this.props.author)
            authorData = ArticleData.AuthorData[this.props.author]
            url = authorData.profile_page
            title = "ArchJobs - "+authorData.name.split(" ")[0]+"'s Articles"
            description = "A list of all articles by "+authorData.name
            ArticleListTitle = "My Articles"
            authorComponent = <div className="AuthorComponent">
                <div className="AuthorProfile">
                    <img src={authorData.profile_img} className="AuthorImg" alt={"Article Author: "+authorData.name}></img>
                    <div className="AuthorName">{authorData.name}</div>
                </div>
                <div className="AuthorDescription">
                    {authorData.description}
                </div>
            </div>
        } else {
            authorComponent =  <div className="AuthorDescription">
                <p>{subtitle}</p>
            </div>

        }

        let ArticleElements = []

        ArticleList.forEach(article => {
            ArticleElements.push(<Link to={article.article_url.replace("https://www.archjobs.co.uk", "")} className="ArticlePreview">
                <div className="ArticlePreviewContent">
                    <div className="ArticlePreviewTitle">{article.article_title}</div>
                    <div className="ArticlePreviewPublished">{article.published.toLocaleDateString("en-GB", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</div>
                    <div className="ArticlePreviewDescription">{article.article_description}</div>
                </div>
                <img className="ArticlePreviewImage" src={article.thumbnail_img} alt={article.article_title}/>
            </Link>)
        })

        return <div className="ArticlePage ArticleListPage">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <link rel="canonical" href={url} />
            <meta name="description" content={description}/>
            <meta name="og:description" content={description}/>
            <meta name="og:title" content={title}/>
            <meta name="og:type" content={"website"}/>
            <meta name="og:url" content={url}/>
        </Helmet>
        <div className="ArticleContainer">
            <div className="ArticleListTitle">{ArticleListTitle}</div>
            {authorComponent}
            <div className="ArticleList">{ArticleElements}</div>
            {!this.props.dubai && <div className="NewsletterEmailContainer">
                <div className="NewsletterEmailTitle">Get the latest jobs, career insights and industry updates with our weekly newsletter.</div>
                <WeeklyEmail/>
            </div>}
        </div>

    </div>
    }
}

export default withRouter(ArticleList)