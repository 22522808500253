
import React from 'react'
import classNames from 'classnames';
import '../css/navbar.css'
import {BsLinkedin, BsTwitter, BsFillBellFill} from 'react-icons/bs'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import ArticleData from "../articles/ArticleData"
import { roles } from "./SalaryGuide"
  const Jobs =  ['Architect', "Architectural Technologist",'Associate Architect', 'BIM Manager', 'BIM Coordinator/Consultant', 'Director', 'Landscape Architect', 'Part 1 Architectural Assistant', 'Part 2 Architectural Assistant', "Part 3", 'Project Architect', 'Senior Architect', "Senior Architectural Technologist", "Senior Landscape Architect", "Urban Designer"]

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            open: false,
        }
      }

    render() {

        var link_list = []

        const sortedJobs = Jobs.sort()
        sortedJobs.forEach(Job => {
            link_list.push(<Link to={"/search/"+Job.replace(/[\W_]+/g, "-")+"-Jobs-in-United-Kingdom"} className="Link">{Job} Jobs</Link>)
        })
        // article_title: "A Guide to Answering Common Interview Questions and Understanding Their Purpose",
        // article_description: "Whether you are just starting your job search or have been through several interviews, it's helpful to know what to expect. In this article, we will cover some of the most common interview questions that you may be asked, along with tips for answering them effectively. From discussing your strengths and weaknesses to describing your approach to handling stress and conflict, these questions are designed to give the interviewer a sense of your skills and fit for the role.",
        // article_url: "https://www.archjobs.co.uk/articles/how-to-answer-common-interview-questions",
        console.log(ArticleData)
        let article_list = []
        for (const article in ArticleData.ArticleData){
            if (ArticleData.ArticleData[article].article_url && ArticleData.ArticleData[article].article_title !== "Title"){
                article_list.push(<Link to={ArticleData.ArticleData[article].article_url.replace("https://www.archjobs.co.uk", "")} className="Link ArticleLink">{ArticleData.ArticleData[article].article_title}</Link>)
            }
            
        }

        let salary_list = []
        roles.forEach(role => {
            if (Jobs.includes(role.aj_title))
            salary_list.push(<Link to={"/salary-guide/"+role.aj_title.replace(/ /g, '-').toLowerCase()+"-salary"} className="Link ArticleLink">{role.aj_title} Salary</Link>)
        })

      return (
        <div className="Footer">
            <div className="FooterContainer" id="FooterContainer">
                <div className="FooterContent">
                    <div className="FooterSearches">
                        <div className="FooterTitle">Popular UK Architectural Jobs</div>
                        {link_list}
                    </div>
                    <div className="FooterSearches">
                        <div className="FooterTitle">Popular Architectural Salaries UK</div>
                        {salary_list}
                    </div>
                    {/* <div className="FooterSearches">
                        <div className="FooterTitle">Our Articles</div>
                        {article_list}
                    </div> */}
                    <div className="FooterSearches">
                        <div className="FooterTitle">Job Seekers</div>
                        <Link className="Link" to="/architectural-career-coaching">Career Coaching</Link>
                        {/* <Link className="Link" to="/recruiters">Find a Recruiter</Link> */}
                        <Link className="Link" to="/salary-guide">UK Salary Guide</Link>
                        <Link className="Link" to="/articles">Articles & Advice</Link>
                        <Link className="Link" to="/unpaid-overtime-calculator">Unpaid Overtime Calculator</Link>
                        <Link className="Link" to="/newsletter">Newsletter - Sign Up</Link>
                        <p/>
                        <div className="FooterTitle">Clients</div>
                        <Link className="Link" to="/post-job">Submit a Job</Link>
                        {/* <Link className="Link" to="/hiring">Hiring Hub</Link> */}
                        <Link className="Link" to="/post-job">Testimonials</Link>
                        <Link className="Link" to="/faq">FAQ</Link>
                        <Link className="Link" to="/the-team">Meet the Team</Link>
                        <a className="Link" href="mailto:hello@archjobs.co.uk" target="_blank">Contact Us</a>
                        <p/>
                        {/* <div className="FooterTitle">Recruiters</div>
                        <Link className="Link" to="/recruiters/join-us">Packages & Pricing</Link> */}
                        {/* <Link className="Link" to="/recruiters">Find a Recruiter</Link> */}
                        {/* <Link className="Link" to="/recruiters/faq">FAQ</Link> */}
                        <p/>
                        {/* <div className="FooterTitle">The Team</div>
                        <Link className="Link" to="/the-team">Meet the Team</Link> */}
                        
                    </div>
                    <div className="FooterLogoContainer">
                        <Link className="FooterLogo" to="/">
                            {/* <img alt="ArchJobs Logo" src="ArchJobs_Logo_36x36.png" className="FooterLogoImg"/> */}
                            ArchJobs
                        </Link>
                        <div>Made in Scotland - Grown in Dubai
                        </div>
                        <div className="SocialMediaLinks">
                            
                            <a className="Link" href="https://www.linkedin.com/company/archjobsuk/" target="_blank"><BsLinkedin className="LinkedinLogo"></BsLinkedin></a>
                            {/* <a className="Link" href="https://twitter.com/ArchJobsUK" target="_blank"><BsTwitter className="TwitterinLogo"></BsTwitter></a> */}
                        </div>
                    </div>
 
                </div>
                <div className="FooterSubtext">
                    <span> All our data is gathered from publicly available sources or contributed by users </span>
                    <Link to="/privacy">Privacy Policy</Link>
                </div>
            </div>

        </div>
      )
    }
  }

  export default Footer