import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';
import { JumpToID } from "../functions/JumpToID";

//first go to ArticleData.js and add all the relavant data
//next, rename "YourArticle" below to what you named the article data section
const thisArticleData = "LivingWages"

export const articleContent = <div className="LivingWageArticle">
          <p>Many of you are likely aware of the challenges in the UK architectural sector - low salaries, unpaid overtime, long working hours, and the struggle to secure your first Part 1 or Part 2 Architectural Assistant role. Toxic work cultures also add to the mix. In this article, I will delve into UK Architectural Salaries, the distinction between Living Wage and National Minimum Wage, what to expect in terms of compensation at a RIBA Chartered Practice, and more. I'll provide guidance on protecting yourself and offer contacts in case you're not being paid correctly. This information is particularly valuable for juniors and overseas qualified professionals unfamiliar with the UK market.&nbsp;</p>
          <p/>
          <p/>
          <h2>The Structure of this Article</h2>
          <ol>
            <li className="Link" onClick={() => {JumpToID("uk-architectural-salaries")}}>UK Architectural Salaries</li>
            <li className="Link" onClick={() => {JumpToID("uk-minimum-wage")}}>UK Living Wages Explained</li>
            <ol>
              <li className="Link" onClick={() => {JumpToID("LivingWage")}}>RIBA Chartered Practice and Living Wage</li>
              {/* <li>London Living Wage&nbsp;</li> */}
              <li className="Link" onClick={() => {JumpToID("MinimumWage")}}>National Minimum Wage (NMW)</li>
            </ol>
            <li className="Link" onClick={() => {JumpToID("flextime-toil")}} >Overtime, Flextime and TOIL</li>
            <li className="Link" onClick={() => {JumpToID("advice")}}>What to do if...</li>
          </ol>
          <p/>
          <h2 id = "uk-architectural-salaries">Section 1: UK Architectural Salaries</h2>
          <p>Let&rsquo;s start with architectural salaries. You might be aware of this, but UK salaries aren&rsquo;t as high as you might&rsquo;ve thought or hoped. This has been an ongoing issue for many many years where salaries aren&rsquo;t increasing at the same rate as the rise of cost of living, while everything else around us is getting more expensive. It is not uncommon that juniors are being paid National Minimum Wage salaries, which is still ridiculous after you take into account that they studied.&nbsp;</p>
          <p>If you're searching for jobs in London, the salaries are higher than across the rest of the UK, but remember that living costs are much higher too. I'll break down the salaries based on Job Title and whether it's in London or outside London.</p>
          <p/>
          <p/>
          <h3>What Are the Salary Ranges for Architects in the UK (2025)?</h3>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td><strong>Job Title</strong></td>
          <td><strong>London</strong></td>
          <td><strong>Outside London</strong></td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/part-1-architectural-assistant-salary" target="_blank" rel="noopener">Part 1 Architectural Assistant</a></td>
          <td>&pound;23.000 - &pound;25.000</td>
          <td>&pound;20.825 - &pound;22.500</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/part-2-architectural-assistant-salary" target="_blank" rel="noopener">Part 2 Architectural Assistant</a></td>
          <td>&pound;28.000 - &pound;33.000</td>
          <td>&pound;23.000 - &pound;26.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/part-3-salary" target="_blank" rel="noopener">Part 3&nbsp;</a></td>
          <td>&pound;30.000 - &pound;35.000</td>
          <td>&pound;28.000 - &pound;30.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/architect-salary" target="_blank" rel="noopener">Architect</a></td>
          <td>&pound;35.000 - &pound;40.000</td>
          <td>&pound;31.000 - &pound;37.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/project-architect-salary" target="_blank" rel="noopener">Project Architect</a></td>
          <td>&pound;39.000 - &pound;45.000</td>
          <td>&pound;36.000 - &pound;39.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/senior-architect-salary" target="_blank" rel="noopener">Senior Architect</a></td>
          <td>&pound;45.000 - &pound;55.000</td>
          <td>&pound;39.000 - &pound;43.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/landscape-architect-salary" target="_blank" rel="noopener">Landscape Architect&nbsp;</a></td>
          <td>&pound;35.000 - &pound;40.000</td>
          <td>&pound;31.000 - &pound;36.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/senior-landscape-architect-salary" target="_blank" rel="noopener">Senior Landscape Architect</a></td>
          <td>&pound;45.000 - &pound;55.000</td>
          <td>&pound;37.000 - &pound;43.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/associate-architect-salary" target="_blank" rel="noopener">Associate Architect</a></td>
          <td>&pound;56.000 - &pound;65.000</td>
          <td>&pound;44.000 - &pound;48.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/director-salary" target="_blank" rel="noopener">Director</a></td>
          <td>&pound;66.000 - &pound;90.000+</td>
          <td>&pound;49.000 - &pound;70.000+</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p>All figures are per year and based on 35 hrs per week and not RIBA Chartered Practices.*</p>
          <p>Please note that salaries will vary between practices and locations, so consider this as a guideline. Also, if you are joining a RIBA Chartered Practice, know that they have to pay you the Living Wage, which is different from the National Minimum Wage. More about that below.</p>
          <p/>
          <p/>
          {/* <h3>UK Skilled Worker Visa - 4th April 2024</h3>
          <p>The UK Government has announced changes to the Skilled Worker Visa salary threshold:</p>
          <p>"A new increase in the minimum salary required for those arriving on the Skilled Worker visa, from £26,200 to £38,700, will be effective on 4th April 2024." Source: <a target="_blank" rel="nofollow noopener" href="https://www.gov.uk/government/news/home-secretary-underlines-commitment-to-cut-net-migration">Gov.uk</a></p>
          <p>Just to put the new figure into perspective, the average salary of an Architect in the UK is £37k per annum. This means it will be quite challenging to find a new role for Overseas Qualified professionals, especially juniors.</p>
          <p/>
          <p/> */}
          <h2 id = "uk-minimum-wage">Section 2: UK Living Wages Explained</h2>
          <p/>
          <p>It is important to understand that there are different Living Wages in the UK. You have the Living Wage, set by the Living Wage Foundation, and the National Minimum Wage, determined by the UK Government.</p>
          <p>Please note: There is also a voluntary London Living Wage, set by the London Mayor, which is separate from the Living Wage Foundation organisation.</p>
          <p/>
          <p/>
          <h3 id="LivingWage">What is the current UK Living Wage for 2025?</h3>
          <p>The current Living Wage in the UK stands at &pound;12.60 per hour and &pound;13.85 per hour in London. Please note, the Living Wage is different from the National Living Wage set by the UK Government.</p>
          <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/LivingWageFoundationNewRates.jpg" alt="Picture of the current Living Wage Foundation rates. UK £12 per hour and London £13.13 per hour."></img> </p>
          <p>Please note, Living Wage accreditation does not require employers to pay the Living Wage to volunteers or apprentices.</p>
          <p/>
          <p/>
          <h3>What is a RIBA Chartered Practice, and what does it have to do with the Living Wage?</h3>
          <p>Being a RIBA Chartered Practice in the UK signifies that the architectural company is officially recognised and accredited by the Royal Institute of British Architects (RIBA). This accreditation is a mark of quality and professionalism within the field of architecture. To achieve and maintain chartered status, a practice must adhere to certain standards, including ethical guidelines, professional conduct, and the provision of quality services.</p>
          <p>Additionally, RIBA Chartered Practices are required to pay at least the Living Wage to all their staff, as defined by the <a href="https://www.livingwage.org.uk/" target="_blank" rel="nofollow noopener">Living Wage Foundation</a>, ensuring fair compensation for employees.</p>
          <p/>
          <p/>
          <h3>So, what does that actually mean?</h3> 
          <p>If you join a RIBA Chartered Practice, they are required to pay all of their staff at least the current Living Wage.</p>
          <p>Let's assume you're a junior receiving a job offer from an RIBA Chartered Practice. Your minimum salary needs to be, depending on the working hours and location:</p>
          <p/> 
          <div>
          <table>
          <tbody>
          <tr>
          <td><strong>Hours per week</strong></td>
          <td><strong>UK (&pound;12.60 per hour)</strong></td>
          <td><strong>London (&pound;13.85 per hour)</strong></td>
          </tr>
          <tr>
          <td><strong>35 hrs</strong></td>
          <td>&pound;22,932</td>
          <td>&pound;25,207</td>
          </tr>
          <tr>
          <td><strong>37.5 hrs</strong></td>
          <td>&pound;24,570</td>
          <td>&pound;27,007.50</td>
          </tr>
          <tr>
          <td><strong>40 hrs</strong></td>
          <td>&pound;26,208</td>
          <td>&pound;28,808</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p>If the practice offers you less than the above figures, they are breaching the RIBA Code of Practice Conduct.&nbsp;</p>
          <p>You can always refer back to the <a href="https://www.architecture.com/knowledge-and-resources/resources-landing-page/code-of-practice-for-chartered-practices" target="_blank" rel="nofollow noopener">RIBA Code of Practice Document</a> - see Section 3.6.3.&nbsp;</p>
          <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/RibaLivingWageRequirements.png" alt="Screenshot from the RIBA Code of Practice Document showing section 3.6.3. saying - Chartered Practices shall pay their staff fairly and facilitate and encourage their professional development - Chartered Practices are required to pay all staff, including freelance staff, apprentices and students, at least the Living Wage, as defined by the Living Wage Foundation..."></img> </p>
          <p/>
          <p/>
          <h3>Apprenticeship Salaries in RIBA Chartered Practices (valid from 1 May 2025)</h3>
          <p>In November 2024, RIBA introduced clear requirements regarding pay for apprentices in Chartered Practices. These practices must ensure apprentices are paid at least the Real Living Wage, as set by the Living Wage Foundation, for the hours they work in practice (typically 80% of their time). For study days (usually 20%), apprentices must receive at least the statutory minimum wage. This statement from RIBA provides much-needed clarity and sets a fair standard for apprenticeship salaries in the architectural profession. </p>
          <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/RIBA Apprentice Salaries Confirmed.png" alt="Screenshot from the Workplace Wellbeing Practice Note - released in Nobember 2024"></img> </p>
          <p>(Source: <a href="https://www.architecture.com/knowledge-and-resources/resources-landing-page/workplace-wellbeing-practice-note?srsltid=AfmBOoo-CZUK0Q8iajgYApAiDBhtO95W4Ib1uAH3G8_DHEOifDSaRNaK" target="_blank" rel="nofollow noopener">Workplace Wellbeing Practice Note</a>)</p>
          <p/>
          <p/>
          <h3>What to do if your RIBA Chartered Practice does not follow Living Wage Standards?</h3>
          <p>If you find yourself working at a RIBA Chartered Practice and realise that your current salary does not meet the Living Wage standards, you have a couple of options. You can either discuss the matter with your director/HR team or reach out to the RIBA team directly, informing them that your current practice is not following the guidelines. You can contact them via email at <a href="mailto:professionalstandards@riba.org" target="_blank" rel="nofollow noopener">professionalstandards@riba.org</a>.</p>
          <p/>
          <p/>
          <h3>How do you know if the Practice is Chartered?</h3>
          <p>There are a few simple ways you can identify RIBA Chartered Practice. You can either go on the <a href="https://find-an-architect.architecture.com/practices/search" target="_blank" rel="nofollow noopener">RIBA website and find a practice by name</a> or you might see the logo on the company's website.&nbsp;</p>
          <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ribacharteredlogo.PNG" alt="RIBA Chartered Practice logo"></img> </p>

          <p>So, the next time you're in an interview at a RIBA Chartered Practice and they inquire about your salary expectations, you can confidently state that, based on your research into current market rates and in alignment with the Living Wage, you are seeking a minimum of &pound;XYZ (depending on the working hours). If they are unwilling to meet the minimum, it's a big red flag. It may be in your best interest to explore other opportunities, and it could be worth considering reaching out to RIBA via email, as such behaviour should not be tolerated - <a href="mailto:professionalstandards@riba.org" target="_blank" rel="noopener">professionalstandards@riba.org</a>.</p>
          <p/>
          <p/>
          {/* <h3>What is the London Living Wage?</h3>
          <p>The London Living Wage is an hourly rate of pay, currently set at &pound;11.95. It is calculated independently to reflect the high cost of living in the capital, giving a worker in London and their family enough to afford the essentials and to save.</p>
          <p>However, organisations must choose to pay their employees the London Living Wage - higher than what they're required to pay by law. Source: <a href="https://www.london.gov.uk/programmes-strategies/business-and-economy/london-living-wage#:~:text=The%20London%20Living%20Wage%20is,currently%20set%20at%20%C2%A311.95" target="_blank" rel="nofollow noopener">London Gov</a></p>
          <p/>
          <p/>
          <h3>Is the London Living Wage different from the Living Wage?</h3>
          <p>Yes, the London Living Wage (LLW) differs from the Living Wage (LW) set by the Living Wage Foundation. The current LLW is &pound;11.95, whereas the LW is higher at &pound;13.15 per hour.</p>
          <p>It's worth mentioning that not every company will commit to paying either of these hourly rates. However, if the practice is RIBA Chartered, it is obligated to pay at least &pound;13.15 per hour. If the practice is not RIBA Chartered and does not pay the London Living Wage, you would be paid the National Minimum Wage.</p>
          <p/> */}
          <h3  id="MinimumWage">What is National Minimum Wage in 2025?</h3>
          <p>The National Minimum Wage (NMW) in the UK is a legally mandated hourly rate that employers must pay their workers. It varies based on the worker's age and whether they are an apprentice.&nbsp;</p>
          <p>The NMW is reviewed annually, typically with increases coming into effect in April. If the government raises the minimum wage, your employer is obligated to increase your pay accordingly.</p>
          <p/>
          <p/>
          <h3>What is the Current National Minimum Wage in 2025?</h3>
          <p>The current rates look as follows:</p>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td><strong>Wage band</strong></td>
          <td><strong>Current rate (from 1 April 2024)</strong></td>
          </tr>
          <tr>
          <td>Age 21 or over (National Living Wage)</td>
          <td>&pound;11.44</td>
          </tr>
          <tr>
          <td>Age 18 to 20</td>
          <td>&pound;8.60</td>
          </tr>
          <tr>
          <td>Under 18</td>
          <td>&pound;6.40</td>
          </tr>
          <tr>
          <td>Apprentice</td>
          <td>&pound;6.40</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p>These rates are effective from 1 April 2024.</p>
          <p/>
          <p/>
          <h3>Annual Salaries based on National Minimum Wage Figures</h3>
          <p>Here's a quick overview to help you understand how much money you should aim to earn per year based on your working hours.</p>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td><strong>Wage Band</strong></td>
          <td><strong>Current Rate</strong></td>
          <td><strong>35 hrs</strong></td>
          <td><strong>37.5 hrs</strong></td>
          <td><strong>40 hrs</strong></td>
          </tr>
          <tr>
          <td><strong>Age 21+</strong></td>
          <td>&pound;11.44</td>
          <td>&pound;20,820.80</td>
          <td>&pound;22,308</td>
          <td>&pound;23,795.20</td>
          </tr>
          <tr>
          <td><strong>Age 18 to 20</strong></td>
          <td>&pound;8.60</td>
          <td>&pound;15,652</td>
          <td>&pound;16,770</td>
          <td>&pound;17,888</td>
          </tr>
          <tr>
          <td><strong>Under 18</strong></td>
          <td>&pound;6.40</td>
          <td>&pound;11,648</td>
          <td>&pound;12,480</td>
          <td>&pound;13,312</td>
          </tr>
          <tr>
          <td><strong>Apprentice</strong></td>
          <td>&pound;6.40</td>
          <td>&pound;11,648</td>
          <td>&pound;12,480</td>
          <td>&pound;13,312</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p>All you have to do is take the current rate x hour x 52 weeks</p>
          <p>Example: &pound;11.44 x 37.5 hrs x 52 weeks = &pound;22,308</p>
          <p/>
          <p/>
      
          <h2 id="flextime-toil">Section 3: Overtime, Flextime and TOIL</h2>
          <p>Within architecture, it is unfortunately common for people to work long hours, sometimes without getting paid for overtime. Therefore, it is important to understand what exactly is classified as overtime and how flextime and TOIL could help if you are not being paid for extra hours.</p>
          <p/>
          <h3>What is Overtime?</h3>
          <p>Overtime is time that you spend doing your job in addition to your normal working hours. Some companies will pay you for overtime but employers do not have to pay workers for overtime. However, and here is the important part, your average pay for the total hours you work must not fall below the <a href="https://www.gov.uk/your-right-to-minimum-wage" target="_blank" rel="nofollow noopener">
            National Minimum Wage</a>. If this happens, you will need to raise this issue with the company or contact Acas&rsquo;s helpline.</p>
          <p/>
          <p/>
          <h3>What is Flextime</h3>
          <p>Flextime, short for flexible working hours, is a work arrangement that allows employees to have more control over their daily schedules. Depending on your company, they might say, because you have worked 3 hours extra this week, you can either start or finish earlier this or next week. But it really depends on your agreement and what is written in your contract.&nbsp;</p>
          <p/>
          <p/>
          <h3>What is TOIL (Time off in lieu)</h3>
          <p>Time off in lieu, otherwise known as time in lieu or TOIL, is when an employer offers time off to workers who have gone above and beyond their contracted hours. Again, don&rsquo;t just expect that every practice will offer you TOIL. It has to be written in your contract.&nbsp;</p>
          <p/>
          <p/>
          <h2>Section 4: What to do if...</h2>
          <p/>
          <p/>
          <h3 id="UnderLivingWage">What to do if your RIBA Chartered Practice does not pay you the correct Living Wage?</h3>
          <p>If you realise that your current salary falls below the Living Wage standards set by the Living Wage Foundation, you have a couple of options. You can either discuss the matter with your director/HR team or reach out to the RIBA team directly, informing them that your current practice is not following the guidelines. You can contact them via email at <a href="mailto:professionalstandards@riba.org" target="_blank" rel="nofollow noopener">professionalstandards@riba.org</a>.</p>
          <p>You can always refer back to the <a href="https://www.architecture.com/knowledge-and-resources/resources-landing-page/code-of-practice-for-chartered-practices" target="_blank" rel="nofollow noopener">RIBA Code of Practice Document</a> - see Section 6.3.&nbsp;</p>
          <p>Please note: If the practice is not RIBA Chartered, do not contact RIBA for support. There are other organisations who will be able to help (see below).</p>
          <p/>
          <p/>
          <h3 id="UnderMinimumWage">What to do if your Employer does not pay National Minimum Wage?</h3>
          <p>First and foremost, it's crucial to recognise that this is against the law. Your employer is obligated to pay you at least the minimum wage.</p>
          <p>Although you would hope the National Minimum Wage and architecture might be an unlikely pair, it's more common than you might think and you will need to take action. It is really important that you keep track of all of your hours and you resolve the issue as quickly as possible. The employer must also resolve any backdated non-payment of minimum wage.</p>
          <p>Here are 3 options you can do if your employer does not pay you NMW:</p>
          <p><strong>Option 1:</strong> Raise the issue with your employer. Hopefully that will already be enough to sort out the problem.</p>
          <p><strong>Option 2:</strong> Contact the Acas helpline. Acas gives employees and employers free, impartial advice on workplace rights, rules and best practice. The Acas helpline is for anyone who needs employment law or workplace advice, including employers, employees and workers.</p>
          <p>Helpline 0300 123 1100 | Open Monday to Friday, 8am to 6pm.</p>
          <p>The advice you receive is free but you may be charged for the call depending on your phone provider and whether you use a mobile or a landline. (Source: <a href="https://www.acas.org.uk/contact" target="_blank" rel="nofollow noopener">Acas</a>)</p>
          <p><strong>Option 3:</strong> Complain about pay and work rights to the Government. You can use the online form to complain about not being paid the National Minimum Wage or facing issues with working hours. <a href="https://www.gov.uk/government/publications/pay-and-work-rights-complaints" target="_blank" rel="nofollow noopener">The form can be found here</a>. Please note, before taking this step, it's advisable to try Options 1 and 2 first.</p>
          <p/>
          <p/> 
          <h3>What to do if your employer does not pay for overtime?</h3>
          <p>Employers do not have to pay workers for overtime. However, your average pay for the total hours you work must not fall below the <a href="https://www.gov.uk/your-right-to-minimum-wage" target="_blank" rel="nofollow noopener">National Minimum Wage</a>.</p>
          <p>Your <a href="https://www.archjobs.co.uk/articles/everything-you-need-to-know-before-signing-an-employment-contract" target="_blank" rel="follow">employment contract</a> will usually include details of any overtime pay rates and how they&rsquo;re worked out.</p>
          <p>There are a lot of employers out there who do not pay their staff for overtime, but what are things an employer could offer you instead? Some companies offer Flextime or TOIL (Time off in lieu). Those are usually good alternatives if you work unpaid overtime.</p>
          <p>Therefore, it is really important to have those details set in your contract. What happens if you work overtime? Will they pay for it, or will they give you Flextime or TOIL? Always make sure you have that written in your contract, if not, ask them to clarify it before you sign.</p>
          <p/>
          <p/>
          <h3>What to do if your hourly rate falls beneath the Living Wage or National Minimum Wage due to excessive unpaid overtime?</h3>
          <p>I've heard many people say to me, 'I work 4 to 7+ hours of unpaid overtime every week, and I'm not getting anything in return - no flextime or TOIL'. The issue is, especially for juniors and overseas professionals looking for their first job, you might already be on a lower salary. If you add unpaid overtime on top of that without getting anything in return, there's a high chance your hourly rate could be below the Living Wage or National Minimum Wage, and that is not allowed.</p>
          {/* <p>Below is an example:</p>
          <p>If you are contracted to work 37.5 hours per week with a base salary set at £23k per year, and you're doing 4 hours of unpaid overtime per week (with no flextime or TOIL), you can see that not only are you lowering your hourly rate from £11.79 to £10.66, but you are also not being paid the National Minimum Wage or the Living Wage of £12.60 per hour, which a RIBA Chartered Practice has to pay you.</p>
          <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/UnpaidOvertimeCalcExample24.png" alt="A screenshot of ArchJobs's Unpaid Overtime Calculator. It will show you how much you should earn, how much you actually earn, and whether your hourly rate meets the National Minimum Wage and/or Living Wage."></img> </p> */}
          <p>To check the impact all the unpaid overtime has on your salary and to see if your hourly rate is above or below the legal range, use our <a href="https://www.archjobs.co.uk/unpaid-overtime-calculator" target="_blank" rel="nofollow">free Unpaid Overtime Calculator</a>. With just a few clicks, you will know the impact of all the additional hours you put in.</p>
          <p/>
          <p/> 
          <h2>Conclusion</h2>
          <p>In the fast-moving world of architecture, where navigating salaries, long working hours, and fast-paced working conditions is crucial, it’s important for you to know what you are entitled to. As we've explored UK architectural salary ranges, the connection between RIBA Chartered Practices and the Living Wage Foundation, the distinctiveness of the National Minimum Wage, and what steps you can take if your employer fails to pay you correctly, it's clear that there is a lot to consider.</p>
          <p>With unpaid overtime nearly unavoidable in the industry, you must ensure that your hourly rate does not fall below the NMW/Living Wage (depending on whether your company is RIBA Chartered or not). This is particularly important for juniors and professionals looking to move to the UK.</p>
          <p>Ensure your Work Contract mentions what happens when working overtime - whether they compensate or offer Flextime and/or TOIL. You can use our <a href="https://www.archjobs.co.uk/unpaid-overtime-calculator" target="_blank" rel="nofollow">free Unpaid Overtime Calculator</a> &nbsp;and see if you are being paid enough. As you've learned, if your salary is below the Living Wage or NMW, address the issue with your employer promptly. Seek advice from organisations such as RIBA (if they are RIBA Chartered), Acas, or the UK Government for guidance.</p>

</div>


//rename YourArticle below to your article name
export default class LivingWages extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }

    componentDidMount = () => {
      window.scrollTo(0, 0)
    }
    render(){
        return <ArticleFrame ArticleDataName={thisArticleData}>
          {articleContent}
        </ArticleFrame>
    }
}